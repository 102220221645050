var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('errorHandeling',{attrs:{"snackbarText":_vm.$t('You have no permission to view this page'),"buttons":[
        
        {
          isText: true,
          functionName: 'clearError',
          text: 'Close',
        },
      ],"snackbarColor":"error","snackbarTimout":"-1","snackbarIcon":"mdi-alert"},on:{"clearError":function($event){return _vm.$store.dispatch('resetError')}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }